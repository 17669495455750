* {
  margin: 0;
  
}

.About-Img {
  max-width: 325px;
  max-height: 325px;
}

a {
  text-decoration: none;
  color: rgb(172, 169, 169);
}

.link-header {
  color: white;

  &:hover{
    color: #1fe628;
    transition-timing-function: ease-in;
    transition: 0.4s;
    transform: translateY(10%);
  }
}

